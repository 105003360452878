/* ====================  IMPORT  ==================== */

// BOOTSTRAP
@import 'bootstrap';


/* ====================  CUSTOM  ==================== */

/* nunito-sans-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 400;
	src: url('fonts/nunito-sans-v12-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
	url('fonts/nunito-sans-v12-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-italic - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: italic;
	font-weight: 400;
	src: url('fonts/nunito-sans-v12-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
	url('fonts/nunito-sans-v12-latin-italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-700 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 700;
	src: url('fonts/nunito-sans-v12-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
	url('fonts/nunito-sans-v12-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-700italic - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito Sans';
	font-style: italic;
	font-weight: 700;
	src: url('fonts/nunito-sans-v12-latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
	url('fonts/nunito-sans-v12-latin-700italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@include media-breakpoint-up(md) {
	html {
		font-size: 1.125rem;
	}
}

body {
	position: relative;
}

.bg-cover {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	&#brainbow {
		@include media-breakpoint-up(lg) {
			background-image: url('/assets/img/brainbow.jpg');
		}
	}
}

.navbar#header-nav {
	transition: $transition-base;
	.container {
		border-bottom: 5px solid rgb(135,201,182);
	}
	&.header-dark {
		background-color: rgba(0, 0, 0, .9);
		.container {
			border-bottom: 0 none;
		}
	}
	.navbar-brand {
		img {
			max-width: 150px;
			@include media-breakpoint-up(md) {
				max-width: 250px;
			}
		}
	}
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
	@include media-breakpoint-up(xl) {
		border-bottom: 1px solid #FFF;
	}
}

section {
	padding: 5rem 0;
	&#hero,
	&#hero-home{
		> .container {
			padding: 140px 0 45px 0;
		}
	}
	&#hero-home {
		min-height: calc(100vh - 50px);
	}
	&#advantages {
		background-image: url('/assets/img/bg-boxes-s.jpg');
		@include media-breakpoint-up(md) {
			background-image: url('/assets/img/bg-boxes-m.jpg');
		}
		@include media-breakpoint-up(xxl) {
			background-image: url('/assets/img/bg-boxes.jpg');
		}
	}
	&#ctabottom .bg-primary {
		padding: 3rem;
		@include media-breakpoint-up(lg) {
			padding: 5rem;
		}
	}
}

#activities-boxes .col {
	margin-bottom: 5rem;
	@include media-breakpoint-up(lg) {
		margin-bottom: 0;
	}
}

h1,
.h1 {
	text-transform: uppercase;
}

h3,
.h3 {
	color: $primary;
}

h5,
.h5 {
	font-weight: 400;
	text-transform: uppercase;
}

h6,
.h6 {
	font-weight: 400;
}

.text-white {
	color: #FFF;
}

.card-speaker {
	box-shadow: $box-shadow-sm;
	margin: 0 auto;
	text-align: center;
	max-width: 300px;
	.card-img-top {
		width: 117px !important;
		height: 117px;
		margin: 0 auto;
		transform: translateY(-50%);
	}
	.card-body {
		margin-top: -30px;
		.card-title {
			a {
				color: $primary;
				text-decoration: none;
			}
		}
	}
}

#program-tab {
	button {
		background-color: #FFF;
		color: $body-color;
		padding: 1.5rem 3rem;
		transition: $transition-base;
		transform: scale(.9);
		span {
			color: $body-color;
		}
		svg {
			fill: $body-color;
		}
		&.active {
			color: $primary;
			box-shadow: $box-shadow-sm;
			span {
				color: $primary;
			}
			svg {
				fill: $primary;
			}
		}
		&:hover {
			transform: scale(1);
		}
	}
}

.arrowlink,
#socials a {
	svg {
		transition: $transition-base;
		transform: scale(1);
	}
	&:hover {
		svg {
			transform: scale(1.1);
		}
	}
}

.venobox {
	transition: $transition-base;
	&:hover {
		opacity: .75;
	}
}

.videoicon {
	position: relative;
	transition: $transition-base;
	&:hover {
		opacity: .75;
	}
	&-container {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
}


// Back to top

#top {
	position: fixed;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #FFF;
	background: $primary;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
	z-index: 1;
	display: none;
	#top-arrow {
		transform: rotate(45deg);
		border-top: 5px solid #fff;
		border-left: 5px solid #fff;
		border-bottom: none;
		border-right: none;
		width: 10px;
		height: 10px;
		margin: 15px auto;
	}
}