/* ======= BOOTSTRAP (5) ======= */

$font-family-sans-serif: 'Nunito Sans', sans-serif;
$headings-font-family: 'Nunito Sans', sans-serif;
$btn-font-family: 'Nunito Sans', sans-serif;
$primary: #87C9B6;
$secondary: #75AF9E;
$light: #EFEFEF;
$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"light": $light
);
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 3.8;
$h2-font-size: $font-size-base * 3;
$h3-font-size: $font-size-base * 2.25;
$h4-font-size: $font-size-base * 1.35;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base * 1.1;
$headings-color: var(--bs-body-color);
$headings-font-weight: 700;
$headings-line-height: 1.3;
$navbar-dark-color: #FFF;
$navbar-dark-hover-color: rgba(#FFF, .75);
$navbar-dark-active-color: #FFF;
$navbar-nav-link-padding-x: 1.5rem;
$card-bg: $light;
$card-border-color: transparent;
$accordion-button-bg: $light;
$accordion-button-active-bg: $secondary;
$accordion-button-active-color: #FFF;
$accordion-body-padding-y: 2rem;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$box-shadow-sm: 0 .1rem .35rem rgba(0, 0, 0, .04);
$box-shadow: 0 .4rem .75rem rgba(0, 0, 0, .07);
$box-shadow-lg: 0 .8rem 2rem rgba(0, 0, 0, .1);


@import '../../../node_modules/bootstrap/scss/bootstrap';